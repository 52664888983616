import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth';

/** Authentication Guard, only authenticated users with JWT Token on localstorage */
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.authenticated()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}