import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http'
import { AuthService } from '../../../services/auth/auth';

import { Transfer } from "./transfer"
import { TransferForm } from "./transfer_form";
import { TransferReceipt } from "./transfer_receipt";
import { Product } from "../../product/product/product";
import { AppConfig } from "../../../app.config";

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";


@Injectable()
export class TransferService {
    config: any;
    private loggedIn = false;
    id_token: string;
    sid: string;
    private serviceUrl = "transfer/";

    constructor(config: AppConfig,
        private _http: HttpClient,
        private _authService: AuthService) {
        this.config = config.getConfig();
        this.id_token = localStorage.getItem('id_token');
        this.sid = localStorage.getItem("sid");
    }

    getAll() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all', { headers: headers });
    }

    getAllNotClosed() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all_open', { headers: headers });
    }

    getAllClosed() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all_close', { headers: headers });
    }

    getOutById(_id: string): any {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'out_by_id?id=' + _id, { headers: headers });
    }
    
    getInById(_id: string): any {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'in_by_id?id=' + _id, { headers: headers });
    }

    getByDateRange(dateA: Date, dateB: Date, warehouse_id_list: Array<String>): any {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        let params = new HttpParams();
        params.append('dateA', dateA.toString());
        params.append('dateB', dateB.toString());
        params.append('warehouse_id_list', warehouse_id_list.toString());
        
        return this._http.get<any>(this.serviceUrl + 'get_by_date_range', { headers: headers, params: params });
    }

    getBySerial(serial: string): any {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_by_serial?serial=' + serial, { headers: headers });
    }

    getByProduct(product: Product) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_product?product_id=' + product.id, { headers: headers });
    }

    update(transfer: Transfer) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.put<any>(
            this.serviceUrl + 'edit',
            transfer,
            { headers: headers });
    }

    /** Transfer_Out Post **/

    transfer_out_post(transferForm: TransferForm) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
            
        return this._http.post<any>(
            this.serviceUrl + 'transfer_out_post',
            transferForm,
            { headers: headers })
            .retryWhen(error => error.delay(500))
            .timeout(this.config.max_timeout)
            .catch(this.handleErrors);
    }

    transfer_receipt_post(transferReceipt: TransferReceipt) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
            
        return this._http.post<any>(
            this.serviceUrl + 'transfer_receipt_post',
            transferReceipt,
            { headers: headers })
            .retryWhen(error => error.delay(500))
            .timeout(this.config.max_timeout)
            .catch(this.handleErrors);
    }

    handleErrors(error: HttpResponse<any>) {
        // console.log("Error: " + JSON.stringify(error.json()));
        console.log("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

}