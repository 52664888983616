import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { AuthService } from '../../services/auth/auth';

import { Stowage } from "./stowage";
import { AppConfig } from "../../app.config";

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import { User } from "../user/user";


@Injectable()
export class StowageService {
    config: any;
    private loggedIn = false;
    id_token: string;
    sid: string;
    private serviceUrl = "stowage/";

    constructor(config: AppConfig,
        private _http: HttpClient,
        private _authService: AuthService) {
        this.config = config.getConfig();
        this.id_token = localStorage.getItem('id_token');
        this.sid = localStorage.getItem("sid");
    }

    getAll() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all', { headers: headers });
    }
    
    getAllActive() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all_active', { headers: headers });
    }

    getById(_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_id?id=' + _id, { headers: headers });
    }
    
    getBySerial(serial: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_serial?serial=' + serial, { headers: headers });
    }
    
    getByProductCatalog(catalog: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_product_catalog?catalog=' + catalog, { headers: headers });
    }
    
    getByStatus(status: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_status?status=' + status, { headers: headers });
    }
    
    getBySerialList(serial_list: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_serial_list?serial_list=' + serial_list, { headers: headers });
    }

    update(stowage: Stowage) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.put(
            this.serviceUrl + 'edit',
            stowage,
            { headers: headers });
    }

    handleErrors(error: HttpResponse<any>) {
        // console.log("Error: " + JSON.stringify(error.json()));
        console.log("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

}