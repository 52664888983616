import { Routes } from '@angular/router';

import { AuthGuard } from './services/auth/auth-guard';
import { ErrorComponent } from './error/error.component';

export const ROUTES: Routes = [{
   path: '', redirectTo: 'app', pathMatch: 'full',
   runGuardsAndResolvers: 'always',
  },
  {
    path: 'app',   loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthGuard], data: { title: 'FortanBiz' }
    // path: 'app',   loadChildren: './layout/layout.module#LayoutModule'
  },
  {
    path: 'login', loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: '**',    component: ErrorComponent
  }
];
