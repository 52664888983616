import { Component, OnInit } from '@angular/core';

// import { MessagingService } from './services/messaging/messaging.service'
import { AuthService } from './services/auth/auth';

import 'rxjs/add/operator/take';
import 'rxjs/add/operator/filter';


@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
  constructor(
    // public msg: MessagingService,
    private auth: AuthService) { }

  ngOnInit() {
    // initialize messaging service if user is authenticated
    if (this.auth.authenticated()) {
      // this.msg.initService(this.auth.user);
    }
  }
}
