import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'sainitizeHtml'})
export class SanitizeHtmlPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer){}

    transform(value: any): SafeHtml {
      return this._sanitizer.bypassSecurityTrustHtml(value);
    }
}