import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http'
import { AuthService } from '../../../services/auth/auth';

import { Receipt, ReceiptDetail } from "./receipt";
import { AppConfig } from "../../../app.config";

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import { Product } from "../../product/product/product";


@Injectable()
export class ReceiptService {
    config: any;
    private loggedIn = false;
    id_token: string;
    sid: string;
    private serviceUrl = "receipt/";

    constructor(config: AppConfig,
        private _http: HttpClient,
        private _authService: AuthService) {
        this.config = config.getConfig();
        this.id_token = localStorage.getItem('id_token');
        this.sid = localStorage.getItem("sid");
    }

    getAll() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all', { headers: headers });
    }

    getAllNotClosed() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_not_closed', { headers: headers });
    }

    getAllClosed() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_closed', { headers: headers });
    }

    getById(_id: string): any {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_id?id=' + _id, { headers: headers });
    }

    getByProduct(product: Product) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_product?product_id=' + product.id, { headers: headers });
    }

    update(receipt: Receipt) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.put<any>(
            this.serviceUrl + 'edit',
            receipt,
            { headers: headers });
    }

    /** Receipt Detail **/

    getAllDetail() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all_detail', { headers: headers });
    }

    getDetailById(_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'detail_by_id?id=' + _id, { headers: headers });
    }

    getDetailByProduct(product: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'detail_by_product?product=' + product, { headers: headers });
    }

    getDetailByRemision(remision: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'detail_by_remision?remision=' + remision, { headers: headers });
    }

    getDetailByWarehouse(warehouse: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'detail_by_warehouse?warehouse=' + warehouse, { headers: headers });
    }

    getDetailBySupplier(supplier: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'detail_by_supplier?supplier=' + supplier, { headers: headers });
    }

    updateDetail(receiptDetail: ReceiptDetail) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.put<any>(
            this.serviceUrl + 'edit_detail',
            receiptDetail,
            { headers: headers });
    }

    deleteDetail(_id: string) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json; charset=utf-8');

        // return this._http.delete(
        return this._http.put<any>(
            this.serviceUrl + 'detail_delete?id=' + _id, 
            { headers: headers });
    }

    /** Process Detail **/

    process_receipt_detail(receiptDetail_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'process_receipt_detail?did=' + receiptDetail_id, { headers: headers });
    }
    
    /** Confirm Detail **/

    confirm_receipt_detail(receiptDetail_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'confirm_receipt_detail?did=' + receiptDetail_id, { headers: headers });
    }
    
    /** Modify Receipt Status **/

    receipt_mod_status(status: number, receipt_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'receipt_mod_status?status='+ status + '&rid=' + receipt_id, { headers: headers });
    }

    handleErrors(error: HttpResponse<any>) {
        // console.log("Error: " + JSON.stringify(error.json()));
        console.log("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

}