import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'

import { AuthService } from '../../services/auth/auth';
import { AppConfig } from "../../app.config";

import { Event } from "./event";
import { Observable } from "rxjs/Observable";

@Injectable()
export class EventService {
    config: any;
    id_token: string;
    sid: string;
    private serviceUrl = "event/";

    constructor(config: AppConfig,
        private http: HttpClient,
        private _authService: AuthService) {
        this.config = config.getConfig();
        this.id_token = localStorage.getItem('id_token');
        this.sid = localStorage.getItem("sid");
    }

    private handleError(error: any): Observable<any> {
        console.error('An error occurred', error);
        return Observable.throw(error || "Server error");
    }

    getAll() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this.http.get<any>(this.serviceUrl + 'get_all', { headers: headers });
    }

    getById(_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this.http.get<any>(this.serviceUrl + 'by_id?id=' + _id, { headers: headers }).map((response: Response) => response.json());
    }

    create(unitData: any) {
        return this.http.post<any>(this.serviceUrl + 'edit', unitData)
            .map(res => {
                let data = res.json();
                if (data.success === false) {
                    throw new Error(data.msg);
                }
                else {
                    return data;
                }
            })
            .catch(this.handleError);;;
    }

    update(event: Event) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');

        return this.http.put(
            this.serviceUrl + 'edit',
            event,
            { headers: headers });
    }

    delete(_id: string) {
        return this.http.delete(this.serviceUrl + 'delete?id=' + _id);
    }

    handleErrors(error: HttpResponse<any>) {
        // console.log("Error: " + JSON.stringify(error.json()));
        console.log("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

}