import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { AuthHttp, JwtHelper, tokenNotExpired } from 'angular2-jwt';

import { Observable } from 'rxjs';

// import { MessagingService } from '../messaging/messaging.service';
import { AppConfig } from '../../app.config';
import { User } from '../../shared/user/user';

import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import 'rxjs/add/operator/timeout';


@Injectable()
export class AuthService {
    config: any;
    jwtHelper: JwtHelper = new JwtHelper();
    refreshSubscription: any;
    user: User;
    private serviceUrl = "";   // autho in root /app/

    constructor(config: AppConfig,
        private authHttp: AuthHttp,
        // private msg: MessagingService,
        private _http: HttpClient) {
        this.config = config.getConfig();
        this.getTokens();
    }

    /**
     * gets auth tokens from localStorage
     */
    getTokens() {
        // Check if there is a profile saved in local storage
        this.user = JSON.parse(localStorage.getItem('profile'));
        this.config.token = localStorage.getItem('id_token');
    }

    /**
     * return auth token
     */
    getToken() {
        return this.config.token;
    }
    
    public authenticated() {
        // Check if there's an unexpired JWT
        // console.log("config.token: ", this.config.token);
        // console.log("tokenNotExpired: ", tokenNotExpired(null, this.config.token));

        return tokenNotExpired(null, this.config.token); // Returns true/false
        // return true; // Returns true/false
    }

    /**
     * Check if current user has role
     * @param [roles] array of string names of roles
     */
    checkRole(roles: string[], user?: User): boolean {
        if (this.config.token) {
            let user_roles = this.jwtHelper.decodeToken(this.config.token).roles

            for (const role of roles) {
                if (user_roles && user_roles.indexOf(role) >= 0) {
                    // role found
                    return true;
                }
            }
        }
        // not found
        return false;
    }

    login(username: string, password: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        console.log("auth service login...");

        return this._http.post<any>(
            this.serviceUrl + "oauth",
            JSON.stringify({
                username: username,
                password: password,
                grant_type: "password"
            }),
            { headers: headers })
            // .retryWhen(error => error.delay(500))
            .timeout(this.config.max_timeout)
            .do(res => this.setSession(res))
            // prevent the receiver of this Observable from accidentally
            // triggering multiple POST request due to multiple subscriptions
            .shareReplay()
            .catch(this.handleErrors);
    }

    private setSession(authResult) {
        // console.log("authResult: " + JSON.stringify(authResult));
        console.log("success: " + authResult.success);
        if (authResult.success) {
            console.log("Authentication SUCCESS!");
            localStorage.setItem('id_token', authResult.id_token);
            localStorage.setItem('profile', JSON.stringify(authResult.user));
            this.user = authResult.user;
            this.config.token = authResult.id_token;
            this.config.sid = "";
        }
    }

    getDecodedToken() {
        let token = localStorage.getItem('id_token');
        return this.jwtHelper.decodeToken(token);
    }

    /**
     * RefreshTokens from server, user must be logged in
     */
    public refreshTokens() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        console.log("auth service get tokens...");

        return this._http.get<any>(
            this.serviceUrl + "current_oauth",
            { headers: headers })
            .retryWhen(error => error.delay(500))
            .timeout(this.config.max_timeout)
            .do(res => this.setSession(res))
            .catch(this.handleErrors);
    }

    public logout() {
        this.config.token = "";
        this.config.sid = "";

        // FCM delete token
        // this.msg.deleteToken(this.user);

        // null user
        this.user = null;

        // Unschedule the token refresh
        this.unscheduleRefresh();
        return Promise.all(
            [
                localStorage.removeItem('profile'),
                localStorage.removeItem('id_token'),
                localStorage.removeItem('refresh_token'),
                // sid: session id for anonymous user
                localStorage.removeItem('sid'),
            ]);
    }

    public scheduleRefresh() {
        // If the user is authenticated, use the token stream
        // provided by angular2-jwt and flatMap the token
        let source = this.authHttp.tokenStream.flatMap(
            token => {
                // The delay to generate in this case is the difference
                // between the expiry time and the issued at time
                let jwtIat = this.jwtHelper.decodeToken(token).iat;
                let jwtExp = this.jwtHelper.decodeToken(token).exp;
                let iat = new Date(0);
                let exp = new Date(0);

                let delay = (exp.setUTCSeconds(jwtExp) - iat.setUTCSeconds(jwtIat));

                return Observable.interval(delay);
            });

        this.refreshSubscription = source.subscribe(() => {
            this.getNewJwt();
        });
    }

    public startupTokenRefresh() {
        // If the user is authenticated, use the token stream
        // provided by angular2-jwt and flatMap the token
        if (this.authenticated()) {
            let source = this.authHttp.tokenStream.flatMap(
                token => {
                    // Get the expiry time to generate
                    // a delay in milliseconds
                    let now: number = new Date().valueOf();
                    let jwtExp: number = this.jwtHelper.decodeToken(token).exp;
                    let exp: Date = new Date(0);
                    exp.setUTCSeconds(jwtExp);
                    let delay: number = exp.valueOf() - now;

                    // Use the delay in a timer to
                    // run the refresh at the proper time
                    return Observable.timer(delay);
                });

            // Once the delay time from above is
            // reached, get a new JWT and schedule
            // additional refreshes
            source.subscribe(() => {
                this.getNewJwt();
                this.scheduleRefresh();
            });
        }
    }

    public unscheduleRefresh() {
        // Unsubscribe fromt the refresh
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    public getNewJwt() {
        // Get a new JWT using the refresh token saved
        // in local storage
        let refreshToken = localStorage.getItem('refresh_token');
        localStorage.setItem('id_token', refreshToken);
    }

    private handleErrors(error: HttpResponse<any>) {
        console.error("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

}