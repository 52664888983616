import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { AuthService } from '../../../services/auth/auth';

import { Product } from "./product";
import { AppConfig } from '../../../app.config';

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";


@Injectable()
export class ProductService {
    config: any;
    private loggedIn = false;
    id_token: string;
    sid: string;
    private serviceUrl = "product/";

    constructor(config: AppConfig,
        private _http: HttpClient,
        private _authService: AuthService) {
        this.config = config.getConfig();
        this.id_token = localStorage.getItem('id_token');
        this.sid = localStorage.getItem("sid");
    }

    getAll() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all', { headers: headers });
    }

    getById(_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_id?id=' + _id, { headers: headers });
    }
    
    getBySupplier(supplier_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_supplier?supplier_id=' + supplier_id, { headers: headers });
    }

    update(product: Product) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.put<any>(
            this.serviceUrl + 'edit',
            product,
            { headers: headers });
    }

    handleErrors(error: HttpResponse<any>) {
        // console.log("Error: " + JSON.stringify(error.json()));
        console.log("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

}