import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/** Custom Pipes **/
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { CurrencyPipe } from '@angular/common';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ErrorComponent } from './error/error.component';

import { Autosize } from 'ng-autosize';

// AuthHttp JWT
import { HttpModule } from '@angular/http';
import { Http } from '@angular/http';
import { AuthHttp, AuthConfig } from 'angular2-jwt';
import { AuthService } from './services/auth/auth';
import { AuthGuard } from './services/auth/auth-guard';
import { RoleGuard } from './services/auth/role-guard';
import { TokenInterceptor } from './services/auth/token.interceptor';

// App services
import { UserService } from './shared/user/user.service';
import { TaxTypeService } from './shared/taxtype/taxtype.service';
import { UomService } from './shared/product/uom/uom.service';
import { CategoryService } from './shared/product/category/category.service';
import { BrandService } from './shared/product/brand/brand.service';
import { ProductService } from './shared/product/product/product.service';
import { WarehouseService } from './shared/warehouse/warehouse/warehouse.service';
import { WarehouseTypeService } from './shared/warehouse/warehousetype/warehousetype.service';
import { CompanyService } from './shared/company/company.service';
import { PatientService } from './shared/patient/patient.service';
import { HospitalService } from './shared/hospital/hospital.service';
import { MedicService } from './shared/medic/medic.service';
import { ReceiptService } from './shared/warehouse/receipt/receipt.service';
import { TransferService } from './shared/warehouse/transfer/transfer.service';
import { StockTypeService } from './shared/warehouse/stocktype/stocktype.service';
import { StocktakeService } from './shared/warehouse/stocktake/stocktake.service';
import { StowageService } from './shared/stowage/stowage.service';
import { AppointmentService } from './shared/appointment/appointment.service';
import { EventService } from './shared/event/event.service';
import { SaleService } from './shared/sale/sale.service';
// import { MessagingService } from './services/messaging/messaging.service';
// import { PushService } from './services/push/push.service';

// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestoreModule } from 'angularfire2/firestore';
// import { AngularFireModule } from '../../node_modules/angularfire2';

export const firebaseConfig = {
  apiKey: "AIzaSyAbIR0fXHV7v6Ehoh6YIj3ZDg0qGy_3tjw",
  authDomain: "fortanbiz-fb.firebaseapp.com",
  databaseURL: "https://fortanbiz-fb.firebaseio.com",
  projectId: "fortanbiz-fb",
  storageBucket: "fortanbiz-fb.appspot.com",
  messagingSenderId: "1050016459016"
};

const APP_PROVIDERS = [
  AppConfig,

  AuthHttp,
  {
    provide: AuthConfig,
    useFactory: getAuthHttp,
    deps: [Http]
  },

  AuthService,
  AuthGuard,
  RoleGuard,
  UserService,
  TaxTypeService,
  UomService,
  CategoryService,
  BrandService,
  ProductService,
  WarehouseService,
  WarehouseTypeService,
  CompanyService,
  PatientService,
  HospitalService,
  MedicService,
  ReceiptService,
  TransferService,
  StockTypeService,
  StocktakeService,
  StowageService,
  AppointmentService,
  EventService,
  SaleService,
  
  // MessagingService,
  // PushService,

  // AngularFirestore
];

export function getAuthHttp(http) {
  return new AuthConfig({
    headerName: 'Authorization',
    headerPrefix: 'Bearer ',
    tokenName: 'auth_token',
    noJwtError: true
  });
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ErrorComponent,
    Autosize,
    SanitizeHtmlPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,

    // AngularFireModule.initializeApp(firebaseConfig),
    // AngularFirestoreModule,

    // AuthHttp JWT
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [
    SanitizeHtmlPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    APP_PROVIDERS,
    CurrencyPipe
  ]
})
export class AppModule { }
