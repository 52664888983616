import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth';

/** 
 * Role Guard, only users defined role and with JWT Token on localstorage 
 * ref: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3
*/
@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;
        return this.authService.checkRole([expectedRole]);
    }
}