import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { AuthService } from '../../services/auth/auth';

import { User } from "./user";
import { AppConfig } from '../../app.config';

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";


@Injectable()
export class UserService {
    config: any;
    private loggedIn = false;
    id_token: string;
    sid: string;
    private serviceUrl = "user/";

    constructor(config: AppConfig,
        private _http: HttpClient,
        private _authService: AuthService) {
        this.config = config.getConfig();
        this.id_token = localStorage.getItem('id_token');
        this.sid = localStorage.getItem("sid");
    }

    getAll() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'get_all', { headers: headers });
    }

    getById(_id: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(this.serviceUrl + 'by_id?id=' + _id, { headers: headers });
    }

    register(user: User) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        console.log(this.serviceUrl + "register",
            JSON.stringify({
                username: user.email_address,
                email: user.email_address,
                password: user.password
            }));

        return this._http.post<any>(
            this.serviceUrl + "register",
            JSON.stringify({
                username: user.email_address,
                email: user.email_address,
                password: user.password
            }),
            { headers: headers })
            .retryWhen(error => error.delay(500))
            .timeout(this.config.max_timeout)
            .catch(this.handleErrors);
    }

    login(user: User) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        console.log("user service to post login...")

        return this._http.post<any>(
            this.serviceUrl + "oauth",
            JSON.stringify({
                username: user.user_name,
                password: user.password,
                grant_type: "password"
            }),
            { headers: headers })
            .do(data => {
                console.log("data: " + JSON.stringify(data));
                if (data.success) {
                    this.config.token = data.auth_token;
                    localStorage.setItem('auth_token', data.auth_token);
                }
            })
            .catch(this.handleErrors);
    }

    update(user: User) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.put<any>(
            this.serviceUrl + 'edit',
            user,
            { headers: headers });
    }

    reset_password(email: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        console.log("user service to post email for reset password form...")

        return this._http.post<any>(
            this.serviceUrl + "resetpasswordform",
            JSON.stringify({
                email: email
            }),
            { headers: headers })
            .retryWhen(error => error.delay(500))
            .timeout(this.config.max_timeout)
            .catch(this.handleErrors);
    }

    getCurrent() {
        // returns current user or false
        // return localStorage.getItem('profile');

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this._http.get<any>(
            this.serviceUrl + "get_current",
            { headers: headers });

    }

    handleErrors(error: HttpResponse<any>) {
        // console.log("Error: " + JSON.stringify(error.json()));
        console.log("Error: " + JSON.stringify(error));
        return Observable.throw(error);
    }

    logout() {
        localStorage.removeItem('auth_token');
        this.loggedIn = false;
    }

    isLoggedIn() {
        return this.isLoggedIn;
    }
}